<template>
  <div class="wrapper">
    <div class="profile-section d-flex px-3 pt-3">
      <ion-button fill="clear" color="black" class="inline-button w-100 ml-n1 stng-btn" @click="accountSettings">
        <div class="w-100 d-flex align-items-center justify-content-start no-white">
          <inline-svg class="mr-2 cogg" src="/icons/cog.svg" />
          Account Settings
        </div>
        <PlusTag v-if="subscriptionLevel" :ultra="subscriptionLevel === 2" size="md" />
      </ion-button>
    </div>
    <hr class="mx-3 my-2" />
    <div class="options pb-3 px-3 d-flex justify-content-between align-items-center">
      <div class="option d-flex align-items-center text-black">
        <ion-toggle :checked="dark" color="primary" mode="ios" class="mr-1 toggle ml-n2" @ionChange="toggleDarkness" />
        Dark mode
      </div>
    </div>
    <hr v-if="isXmas" class="mx-3 my-2" />
    <div v-if="isXmas" class="px-3 pt-0 pb-3 text-center effects">
      Christmas effects
      <div class="actions mb-n2 mt-2">
        <div class="option d-flex align-items-center text-black">
          <ion-toggle class="toggle mr-1 ml-n2" mode="ios" :checked="snow" @ionChange="toggleSnow"></ion-toggle>
          Snow effect
        </div>

        <div class="option d-flex align-items-center text-black">
          <ion-toggle
            class="toggle mr-1 ml-n2"
            mode="ios"
            :checked="lightRope"
            @ionChange="toggleLightRope"
          ></ion-toggle>
          Light rope
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { OverlayInterface } from '@ionic/core';
import { featureFlags } from '@/shared/config/feature-flags';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import PlusTag from '@/shared/components/PlusTag.vue';

const props = defineProps({
  instance: {
    type: Object as () => OverlayInterface,
    default: () => ({}),
  },
});

const emits = defineEmits(['close']);

const dark = ref(mainStore().dark.value);
const subscriptionLevel = ref(authStore().subscriptionLevel.value);

const toggleDarkness = mainStore().toggleDarkness;
const toggleLightRope = mainStore().toggleLightRope;
const toggleSnow = mainStore().toggleSnow;

const snow = ref(mainStore().snow.value);

const lightRope = ref(mainStore().lightRope.value);

const router = useRouter();

const accountSettings = async () => {
  await props.instance.dismiss();
  router.push({ name: 'account' });
};

const isXmas = featureFlags.isXmas;
</script>

<style scoped lang="sass">
.logout-btn
  --background: rgba(33, 65, 99, 1)
  height: 24px
  font-size: 10px
  color: #FFF !important
.wrapper
  width: 240px
.stng-btn
  font-size: 10px
.inline-button
  height: 30px
.rest
  display: block
  *
    font-size: 12px
.options
  *
    font-size: 10px
.profile-picture
  width: 40px
  height: 40px
  min-width: 40px
  background-color: #ae38e5 !important
  color: #FFF
  border-radius: 30px
  cursor: pointer
  background-size: cover
  background-position: center
.toggle
  scale: 0.8
  right: -5px
.effects
  font-size: 14px
  color: #214163
.menu-icon
  width: 18px
  height: 18px

.inline-button
  &:hover
    background-color: rgba(0,0,0,0.1) !important
    border-radius: 6px !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
